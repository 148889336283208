// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

// Changing the hover color of the dropdown links

$dropdown-link-hover-color: #E55088;

// Color Scheme
// Change the hex values below to alter the main color scheme.

$primary:       #5D30FF;
$primary-2:     #833791;
$primary-3:     #2C3038;
$primary-4:		#E55088;


// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "primary-4":  $primary-4
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
    "primary-4":  $primary-4
);